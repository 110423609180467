<template>
    <div id="Owners" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Propietarios
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'activos'}, {id: 'inactive', label:'inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="owners_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Propietario
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && owners_data.length > 0">
                <li-table :data="owners_data" 
                    :allow_headers="owners_headers"
                    pagination_off>
                    <template v-slot:type="data">
                        {{ data.value == 'internal' ? 'Interno' : 'Externo' }}
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectOwner('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectOwner('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectOwner('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && owners_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        :disabled="page > 1"
                        class="form-control bg-secondary text-white border-0"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="owners_modal" :max_width="1200" class="px-3">
                <div class="col-12">
                    <h3 v-if="!selected_id">
                        Añadir Propietario
                    </h3>
                    <h3 v-if="selected_id">
                        Editar Propietario
                    </h3>
                </div>

                <div class="col-12 col-md-4">
                    <li-input label="Nombre(s)"
                        type="text"
                        placeholder="Nombre(s)"
                        v-model="first_name"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.first_name">
                        {{ errors.first_name}}
                    </span>
                </div>

                <div class="col-12 col-md-4">
                    <li-input label="Apellido Paterno"
                        type="text"
                        placeholder="Apellido Paterno"
                        v-model="last_name"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.last_name">
                        {{ errors.last_name}}
                    </span>
                </div>

                <div class="col-12 col-md-4">
                    <li-input label="Apellido Materno"
                        type="text"
                        placeholder="Apellido Materno"
                        v-model="second_surname"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.second_surname">
                        {{ errors.second_surname}}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2">
                    <li-select label="Tipo de propietario"
                        :options="[{id: '0', label: 'Seleccione'}, {id: 'external', label: 'Externo'},{ id: 'internal', label: 'Interno'}]"
                        v-model="type" 
                        variant="secondary"
                        full/>
                    <span class="text-danger" v-if="errors.type">
                        {{ errors.type }}
                    </span>
                </div>


                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="createEditOwner"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="owners_modal = false; clearOwnersData()"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h3>
                        ¿Esta seguro de eliminar este propietario? 
                        <br>
                        Esta acción no puede revertirse
                    </h3>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteOwner"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar este propietario? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activateOwner"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="activate_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                owners_modal: false,
                delete_modal: false,
                activate_modal: false,
                owners_headers: [
                    { key: 'id_owner', label: '#'},
                    { key: 'first_name',label: 'Nombre(s)' },
                    { key: 'last_name', label: 'Apellido Paterno'},
                    { key: 'second_surname', label: 'Apellido Materno'},
                    { key: 'type', label: 'Tipo'},
                    { key: 'edit', width: '10'},
                    { key: 'delete', width: '10'}
                ],
                owners_data: [],
                first_name: null,
                last_name: null,
                second_surname: null,
                type: '0',
                selected_id: null,
                errors: {
                    first_name: null,
                    last_name: null,
                    second_surname: null,
                    type: null
                },
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                owners: 'owners/getOwners',
                owner: 'owners/getOwner',
                last_page: 'owners/getLastPage',
                total_pages: 'owners/getTotalPages'
            })
        },
        methods: {
            //helpers
            clearOwnersData(){
                this.selected_id = null;
                this.first_name = null;
                this.last_name = null;
                this.type = '0'

                for(let error in this.errors){
                    this.errors[error] = null
                }
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('owners/listOwners', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('owners/listOwners', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('owners/listOwners', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.owners === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
                this.owners_data = this.owners.map( owner => {
                    return {
                        ...owner,
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            async selectOwner(type, data) {
                switch(type) {
                    case 'edit':
                        this.selected_id = data.id_owner
                        await this.$store.dispatch('owners/viewOwner', { id_owner: this.selected_id})
                        this.first_name = this.owner.first_name;
                        this.last_name = this.owner.last_name;
                        this.second_surname = this.owner.second_surname;
                        this.type = this.owner.type;
                        this.owners_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_owner
                        this.delete_modal = true
                        return;
                    case 'activate':
                        this.selected_id = data.id_owner
                        this.activate_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditOwner() {

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(_.isEmpty(this.first_name) || _.isNull(this.first_name)) {
                    complete = false
                    this.errors['first_name'] = '*Campo obligatorio'
                    
                }

                if(_.isEmpty(this.last_name) || _.isNull(this.last_name)) {
                    complete = false
                    this.errors['last_name'] = '*Campo obligatorio'
                    
                }

                if(_.isEmpty(this.second_surname) || _.isNull(this.second_surname)) {
                    complete = false
                    this.errors['second_surname'] = '*Campo obligatorio'
                    
                }

                if(this.type === '0') {
                    complete = false
                    this.errors['type'] = '*Campo obligatorio'
                    
                }
                
                
                if(complete) {
                    const payload = {
                        first_name: this.first_name,
                        last_name: this.last_name,
                        second_surname: this.second_surname,
                        type: this.type
                    }

                    if(this.selected_id) {
                        payload.id_owner = this.selected_id
                        await this.$store.dispatch('owners/updateOwner', payload)

                        this.clearOwnersData()
                        this.filterElements()
                        this.owners_modal = false
                        
                    }

                    else {
                        await this.$store.dispatch('owners/addOwner', payload)
                        this.filterElements()
                        this.owners_modal = false
                        this.clearOwnersData()
                        
                    }
                }
            },
            async deleteOwner() {
                await this.$store.dispatch('owners/deleteOwner', { id_owner: this.selected_id, status: false})
                this.filterElements()

                this.delete_modal = false
                this.selected_id = null
            },
            async activateOwner(){
                await this.$store.dispatch('owners/deleteOwner', {id_owner: this.selected_id, status: true})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {

                //list of owners.
                await this.$store.dispatch('owners/listOwners', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false});
                this.owners_data = this.owners.map((owner) => {
                    return  {
                        ...owner,
                        edit: '-',
                        delete: '-'
                    }
                })
                

                this.loading = false
                this.fetch_error = false

            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Owners {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }

            &:focus{
                outline: none;
            }
        }
    }

    input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

    input[type=number] { 
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0; 

    }
</style>
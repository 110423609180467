<template>
    <section class="container-fluid p-0 m-0">

        <div class="row w-100 bg-gray align-items-center justify-content-center" 
			style="min-height: 80px;">
            <div class="col-12 py-3">
                <li-tab @change="setTab($event)"
                    :data="tabs" :tab_selected="selected_tab">
                </li-tab>
            </div>

        </div>
        
        <div class="row p-0 m-0">
            <div class="col-12 p-0">
                <brands v-if="selected_tab == '1'"/>
                <vehicle-types v-if="selected_tab == '2'" />
                <models v-if="selected_tab == '3'" />
                <spare-parts v-if="selected_tab == '4'"/>
                <colors v-if="selected_tab == '5'" />
                <insurance-companies v-if="selected_tab == '6'" />
                <users v-if="selected_tab == '7'" />
                <user-roles v-if="selected_tab == '8'" />
                <owners v-if="selected_tab == '9'"/>
                <regions v-if="selected_tab == '10'"/>
                <document-types v-if="selected_tab == '11'"/>
            </div>
        </div>

    </section>
</template>

<script>
    
    
    //subviews
    import insuranceCompanies from './catalogues/InsuranceCompanies.vue'
    import Brands from './catalogues/Brands.vue'
    import Models from './catalogues/Models.vue'
    import VehicleTypes from './catalogues/VehiclesTypes.vue'
    import SpareParts from './catalogues/SpareParts.vue'
    import Colors from './catalogues/Colors.vue'
    import Users from './catalogues/Users.vue'
    import UserRoles from './catalogues/UserRoles.vue'
    import Owners  from './catalogues/Owners.vue'
    import Regions from './catalogues/Regions.vue'
    import DocumentTypes from './catalogues/DocumentTypes.vue'

    export default {
        components: {
            Brands,
            Models,
            SpareParts,
            Colors,
            VehicleTypes,
            Users,
            UserRoles,
            insuranceCompanies,
            Owners,
            Regions,
            DocumentTypes 
        },
        data(){
            return {
                tabs: [
                    {key: '1', label: 'Marcas'},
                    {key: '2', label: 'Tipos de Vehiculos'},
                    {key: '3', label: 'Modelos'},
                    {key: '4', label: 'Refacciones'},
                    {key: '5', label: 'Colores de Vehiculos'},
                    {key: '6', label: 'Compañias de seguros'},
                    {key: '7', label: 'Usuarios'},
                    {key: '8', label: 'Roles de usuario'},
                    {key: '9', label: 'Propietarios'},
                    {key: '10', label: 'Regiones'},
                    {key: '11', label: 'Documentos'}
                ],
                selected_tab: '1',
            }
        },
        methods: {
            setTab(data) {
                this.selected_tab = data.key
            }
        }
    }
</script>

<style scoped>

</style>

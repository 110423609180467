<template>
    <div id="Users" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Usuarios
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0 justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="users_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Usuario
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative text-white">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && users_data.length > 0">
                <li-table :data="users_data" 
                    :allow_headers="users_headers"
                    pagination_off>
                    <template v-slot:psd_edit="data">
                        <button @click.stop="selectUser('psd_edit', data.item)"
                            class="btn btn-outline-primary btn-pill p-0 inter-bold"
                            style="width: 180px;"
                            type="button">
                            Editar contraseña
                        </button>
                    </template>
                    <template v-slot:edit="data">
                        <button @click.stop="selectUser('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectUser('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectUser('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && users_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        :disabled="page > 1"
                        class="form-control bg-secondary text-white border-0"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="users_modal" :max_width="1200" class="px-3">
                <div class="col-12">
                    <h3 v-if="!selected_id">
                        Añadir Usuario
                    </h3>
                    <h3 v-if="selected_id">
                        Editar Usuario
                    </h3>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Nombre del usuario"
                        _id="username"
                        type="text"
                        placeholder="Añade el nombre de usuario a registrar"
                        v-model="username"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.username">
                        {{ errors.username }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Rol de usuario"
                        :options="user_role_options"
                        v-model="id_user_role" 
                        variant="secondary"
                        full/>
                    <span class="text-danger" v-if="errors.id_user_role">
                        {{ errors.id_user_role }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Nombre"
                        _id="first_name"
                        type="text"
                        placeholder="Añade el nombre del usuario a registrar"
                        v-model="first_name"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.first_name">
                        {{ errors.first_name }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Apellido paterno"
                        _id="last_name"
                        type="text"
                        placeholder="Añade el apellido paterno del usuario a registrar"
                        v-model="last_name"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.last_name">
                        {{ errors.last_name }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Apellido materno"
                        _id="second_surname"
                        type="text"
                        placeholder="Añade el apellido materno del usuario a registrar"
                        v-model="second_surname"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.second_surname">
                        {{ errors.second_surname }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Teléfono"
                        _id="phone"
                        type="number"
                        placeholder="(00)-00-00-00-00-00"
                        v-model="phone"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.phone">
                        {{ errors.phone }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold" v-if="!selected_id">
                    <li-input label="Contraseña"
                        _id="password"
                        type="password"
                        placeholder="Contraseña"
                        v-model="password"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.password">
                        {{ errors.password }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold" v-if="!selected_id">
                    <li-input label="Confirmar Contraseña"
                        _id="confirm_password"
                        type="password"
                        v-model="confirm_password"
                        placeholder="Contraseña"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.confirm_password">
                        {{ errors.confirm_password }}
                    </span>
                </div>

                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="createEditUser"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="clearUserData"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de eliminar esta usuario? 
                        <br>
                        Esta acción no puede revertirse
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteUser"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="psd_edit_modal" :max_width="600" class="px-3">
                <div class="col-12 text-start">
                    <h4>
                        Editar contraseña
                    </h4>
                </div>

                <div class="col-12">
                    <li-input label="Contraseña:"
                        _id="psd_edit"
                        type="password"
                        placeholder="Nueva contraseña"
                        v-model="edit_password"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.edit_password">
                        {{ errors.edit_password }}
                    </span>
                </div>

                <div class="col-12">
                    <li-input label="Confirmar contraseña:"
                        _id="psd_edit_confirmation"
                        type="password"
                        placeholder="Confirmar contraseña"
                        v-model="edit_confirm_password"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.edit_confirm_password">
                        {{ errors.edit_confirm_password }}
                    </span>
                </div>


                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="editPassword"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="psd_edit_modal = false; clearUserData()"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar este usuario? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activateUser"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="activate_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                users_modal: false,
                delete_modal: false,
                psd_edit_modal: false,
                activate_modal: false,
                users_headers: [
                    { key: 'username', label: 'Usuario', width: '25'},
                    { key: 'rol_name', label: 'Rol'},
                    { key: 'full_name', label: 'Nombre completo'},
                    { key: 'phone', label: 'Teléfono'},
                    { key: 'psd_edit', width: '15'},
                    { key: 'edit', width: '10'},
                    { key: 'delete', width: '10'}
                ],
                users_data: [],
                selected_id: null,
                username: null,
                errors: {
                    username: null,
                    first_name: null,
                    last_name: null,
                    second_surname: null,
                    phone: null,
                    password: null,
                    confirm_password: null,
                    id_user_role: null,
                    edit_password: null,
                    edit_confirm_password: null
                },
                user_role_options: [],
                id_user_role: '0',
                first_name: null,
                last_name: null,
                second_surname: null,
                phone: null,
                password: null,
                confirm_password: null,
                edit_password: null,
                edit_confirm_password: null,
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2'
            }
        },
        computed: {
            ...mapGetters({
                users: 'users/getUsers',
                user: 'users/getUser',
                user_roles: 'user_roles/getUserRoles',
                last_page: 'users/getLastPage',
                total_pages: 'users/getTotalPages'
            })
        },
        methods: {
            //helpers
            clearUserData(){
                this.username = null
                this.first_name = null
                this.last_name = null
                this.second_surname = null
                this.id_user_role = '0'
                this.password = null
                this.confirm_password = null
                this.phone = null
                this.selected_id = null
                this.users_modal = false

                this.edit_password = null
                this.edit_confirm_password = null

                for(let error in this.errors){
                    this.errors[error] = null
                }
            },
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }
                
                let order = this.order_asc == '2' ? false : true;

                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('users/listUsers', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('users/listUsers', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('users/listUsers', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.users === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.users_data = this.users.map( user => {
                    return {
                        ...user,
                        psd_edit: '-',
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            //crud users
            async selectUser(type, data) {
                switch(type) {
                    case 'psd_edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_user
                        this.psd_edit_modal = true;
                        return;
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_user
                        await this.$store.dispatch('users/viewUser', { id_user: this.selected_id})
                        this.username = this.user.username
                        this.first_name = this.user.first_name
                        this.last_name = this.user.last_name
                        this.second_surname = this.user.second_surname
                        this.phone = this.user.phone
                        this.id_user_role = this.user.id_rol.toString()
                        this.users_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_user
                        this.delete_modal = true
                        return;
                    case 'activate':
                        this.selected_id = data.id_user
                        this.activate_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditUser() {
               
                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(_.isEmpty(this.username) || _.isNull(this.username)) {
                    complete = false
                    this.errors['username'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.first_name) || _.isNull(this.first_name)) {
                    complete = false
                    this.errors['first_name'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.last_name) || _.isNull(this.last_name)) {
                    complete = false
                    this.errors['last_name'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.second_surname) || _.isNull(this.second_surname)) {
                    complete = false
                    this.errors['second_surname'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.phone) || _.isNull(this.phone)) {
                    complete = false
                    this.errors['phone'] = '*Campo obligatorio'
                }

                if(_.isEmpty(this.id_user_role) || _.isNull(this.id_user_role) || this.id_user_role == '0') {
                    complete = false
                    this.errors['id_user_role'] = '*Campo obligatorio' 
                }

                if((_.isEmpty(this.password) || _.isNull(this.password)) && _.isNull(this.selected_id)) {
                    console.log('here')
                    complete = false
                    this.errors['password'] = '*Campo obligatorio'
                }

                if((_.isEmpty(this.confirm_password) || _.isNull(this.confirm_password)) && _.isNull(this.selected_id)) {
                    console.log('here2')
                    complete = false
                    this.errors['confirm_password'] = '*Campo obligatorio'
                }

                else if(this.password !== this.confirm_password && _.isNull(this.selected_id)) {
                    complete = false
                    this.errors['confirm_password'] = '*Las contraseñas deben ser iguales'
                }

                if(complete) {
                    const payload = {
                        username: this.username,
                        id_rol: this.id_user_role,
                        first_name: this.first_name,
                        last_name: this.last_name,
                        second_surname: this.second_surname,
                        phone: this.phone,
                        password: this.password
                    }

                    //console.table(payload)

                    if(this.selected_id) {
                        payload.id_user = this.selected_id
                        await this.$store.dispatch('users/updateUser', payload)
                        this.filterElements()
                        this.clearUserData()
                        this.users_modal = false
                        this.selected_id = null
                    }

                    else {
                        await this.$store.dispatch('users/addUser', payload)
                        this.filterElements()
                        this.clearUserData()
                        this.users_modal = false    
                    }
                }
            },
            async deleteUser() {
                await this.$store.dispatch('users/deleteUser', { id_user: this.selected_id, status: 'inactive'})
                this.filterElements()

                this.delete_modal = false
                this.selected_id = null
            },
            async editPassword() {

                this.errors['edit_password'] = null
                this.errors['edit_confirm_password'] = null

                let complete = true

                if(_.isEmpty(this.edit_password) || _.isNull(this.edit_password)) {
                    this.errors.edit_password = '*Campo obligatorio'
                    
                }

                if(_.isEmpty(this.edit_confirm_password) || _.isNull(this.edit_confirm_password)) {
                    this.errors.edit_confirm_password = '*Campo obligatorio'
                }

                else if(this.edit_password !== this.edit_confirm_password) {
                    this.errors.edit_confirm_password = 'Las contraseñas deben ser iguales'
                    return
                }

                if(complete) {
                    const payload = {
                        id_user: this.selected_id,
                        password: this.edit_password
                    }

                    await this.$store.dispatch('users/changePassword', payload)
                    this.clearUserData()
                    this.psd_edit_modal = false
                }

                
            },
            async activateUser(){
                await this.$store.dispatch('users/deleteUser', {id_rol: this.selected_id, status: 'active'})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {
                

                //opciones de roles de usuario
                await this.$store.dispatch('user_roles/listUserRoles', { datatable: false, status: 'active'})
                this.user_role_options = this.user_roles.map( role => {
                    return {
                        id: role.id_rol,
                        label: role.name
                    }
                }) 

                this.user_role_options = [
                    {id: '0', label: 'Seleccione'},
                    ...this.user_role_options
                ]

                //lista de usuarios
                await this.$store.dispatch('users/listUsers', { datatable: true, page: this.page, rows: this.rows, status: 'active',order_asc: false})
                this.users_data = this.users.map( user => {
                    return {
                        ...user,
                        psd_edit:'-',
                        edit: '-',
                        delete: '-'
                    }
                })

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                this.user_role_options = [{id: 'Error', label: 'Error: Intente más tarde'}]
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Users {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>
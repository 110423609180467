<template>
    <div id="Brands" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title">
                    Compañias de seguros
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end">
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'active', label: 'Elementos activos'}, {id: 'inactive', label:'Elementos inactivos'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end">
                        <button @click.stop="companies_modal = true"
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir Compañia
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && companies_data.length > 0">
                <li-table :data="companies_data" 
                    :allow_headers="companies_headers"
                    pagination_off>
                    <template v-slot:edit="data">
                        <button @click.stop="selectCompany('edit', data.item)"
                            class="btn btn-outline-warning btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Editar
                        </button>
                    </template>
                    <template v-slot:delete="data">
                        <button @click.stop="selectCompany('delete', data.item)" v-if="data.item.active"
                            class="btn btn-outline-danger btn-pill p-0 inter-bold"
                            style="width: 90px;"
                            type="button">
                            Eliminar
                        </button>
                        <button @click.stop="selectCompany('activate', data.item)" v-if="!data.item.active"
                            class="btn btn-outline-primary btn-pill p-0 interbold"
                            style="width: 90px;"
                            type="button">
                            Activar
                        </button>
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && companies_data.length > 0">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        class="form-control bg-secondary text-white border-0"
                        :disabled="page > 1"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="companies_modal" :max_width="1200" class="px-3">
                <div class="col-12 col-md-6">
                    <h3 v-if="!selected_id">
                        Añadir Compañia
                    </h3>
                    <h3 v-if="selected_id">
                        Editar Compañia
                    </h3>
                </div>

                <div class="col-12 p-2 opensans-bold">
                    <li-input label="Nombre de la compañia del seguro"
                        type="text"
                        placeholder="Añade el nombre la compañia a registrar"
                        v-model="name"
                        full>
                    </li-input>
                    <span class="text-danger"  v-if="errors.name">
                        {{ errors.name }}
                    </span>
                </div>

                <div class="col-12 d-flex justify-content-end mt-2">
                    <button @click.stop="createEditCompany"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="companies_modal = false; name = null; selected_id = null; errors.name = null"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="delete_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de eliminar esta Compañia? 
                        <br>
                        Esta acción no puede revertirse
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="deleteCompany"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="delete_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>

        <li-modal :show_modal="activate_modal" :max_width="600" class="px-3">
                <div class="col-12 text-center">
                    <h4>
                        ¿Esta seguro de activar esta compañia? 
                    </h4>
                </div>


                <div class="col-12 d-flex justify-content-center mt-2">
                    <button @click.stop="activateCompany"
                        class="btn btn-pill"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                        Aplicar
                    </button>
                        
                    <button @click.stop="activate_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                        cerrar
                    </button>
                </div>

        </li-modal>
        
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                companies_modal: false,
                delete_modal: false,
                activate_modal: false,
                companies_headers: [
                    { key: 'name', label: 'Compañia'},
                    { key: 'edit', width: '10'},
                    { key: 'delete', width: '10'}
                ],
                companies_data: [],
                selected_id: null,
                name: null,
                errors: {
                    name: null
                },
                filter: 'active',
                 //pagination
                page: 1,
                rows: 10,
                order_asc: '2',
            }
        },
        computed: {
            ...mapGetters({
                companies: 'insurance_companies/getCompanies',
                company: 'insurance_companies/getCompany',
                last_page: 'brands/getLastPage',
                total_pages: 'brands/getTotalPages'
            })
        },
        methods: {
            //helpers
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                switch(this.filter) {
                    case 'active':
                        await this.$store.dispatch('insurance_companies/listCompanies', { datatable: true, page: this.page, rows: this.rows, status: 'active', search: search, order_asc: order})
                        break;
                    case 'inactive':
                        await this.$store.dispatch('insurance_companies/listCompanies', { datatable: true, page: this.page, rows: this.rows, status: 'inactive', search: search, order_asc: order})
                        break;
                    case 'all':
                        await this.$store.dispatch('insurance_companies/listCompanies', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order})
                        break;
                }

                if(this.companies === null) {
                    this.empty_search = true
                    return
                }

                else {
                    this.empty_search = false
                }
                
               this.companies_data = this.companies.map( company => {
                    return {
                        ...company,
                        edit: '-',
                        delete: '-'
                    }
                })
            },
            async selectCompany(type, data) {
                switch(type) {
                    case 'edit':

                        if(!data.active) {
                            this.$store.commit('setAlert', {
                                open: true,
                                message: 'No se permite la edición de elementos inactivos',
                                text_color: 'warning'
                            }, { root: true})
                            return
                        }

                        this.selected_id = data.id_insurance_company
                        await this.$store.dispatch('insurance_companies/viewCompany', { id_insurance_company: this.selected_id})
                        this.name = this.company.name
                        this.companies_modal = true
                        return;
                    case 'delete':
                        this.selected_id = data.id_insurance_company
                        this.delete_modal = true
                        return;
                    case 'activate':
                        this.selected_id = data.id_insurance_company
                        this.activate_modal = true
                        return;
                    default:
                        console.log('No es un caso valido')
                        return
                }
            },
            async createEditCompany() {

                let complete = true;
                for(let error in this.errors){
                    this.errors[error] = null
                }

                if(_.isEmpty(this.name) || _.isNull(this.name)) {
                    complete = false
                    this.errors['name'] = '*Campo obligatorio'
                    return
                }

                if(complete) {
                    const payload = {
                        name: this.name
                    }

                    if(this.selected_id) {
                        payload.id_insurance_company = this.selected_id
                        await this.$store.dispatch('insurance_companies/updateCompany', payload)
                        this.filterElements()
                        this.companies_modal = false
                        this.name = null
                        this.selected_id = null
                    }

                    else {
                        await this.$store.dispatch('insurance_companies/addCompany', payload)
                        this.filterElements()

                        this.companies_modal = false
                        this.name = null
                        
                    }
                }
            },
            async deleteCompany() {
                await this.$store.dispatch('insurance_companies/deleteCompany', { id_insurance_company: this.selected_id, status: 'inactive'})
                this.filterElements()

                this.delete_modal = false
                this.selected_id = null
            },
            async activateCompany(){
                await this.$store.dispatch('insurance_companies/deleteCompany', { id_insurance_company: this.selected_id, status: 'active'})
                this.filterElements()
                this.activate_modal = false
                this.selected_id = null
            },

            //pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                console.log('next')
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {
                //lista de marcas
                await this.$store.dispatch('insurance_companies/listCompanies', {datatable: true, page: this.page, rows: this.rows, status: 'active', order_asc: false})
                
                this.companies_data = this.companies.map( company => {
                    return {
                        ...company,
                        edit: '-',
                        delete: '-'
                    }
                })

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #Brands {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>